import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  isEdit = false
  maxLoyaltyPoints = 0

  constructor(
    public _customerService: CustomersService,
    private _connMessageService: ConnMessageService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.maxLoyaltyPoints = this._abd.systemConfigs.loyaltyConfig.maxAllowedPoints
    let obj = this._customerService.activeItem

    obj.lastPointsRedeemedDate = new Date(obj.lastPointsRedeemedDate)
    if(obj.lastPointsRedeemedDate.getFullYear() < 2000) obj.lastPointsRedeemedDate = null
    else formatDate(obj.lastPointsRedeemedDate, 'MM/dd/yyyy', 'en-US')

    obj.lastPointsUpdated = new Date(obj.lastPointsUpdated)
    if(obj.lastPointsUpdated.getFullYear() < 2000) obj.lastPointsUpdated = null
    else formatDate(obj.lastPointsUpdated, 'MM/dd/yyyy', 'en-US')
  }

  saveLoyaltyInfoClicked() {
    let obj = this._customerService.activeItem
    let model = {
      "CustID": obj.custID,
      "RewardPoints": obj.rewardPoints
    }
    this._spinner.show()
    try {
      this._customerService.updateCustomerLoyalty(model).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this._connMessageService.showToastMessage(`Customer's Loyalty information saved successfully!`, 'success');
        }
      })
    }
    catch {
      this._spinner.hide()
    }
  }
  backButtonClicked(){
    window.history.back() 
}
}
