import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import { Subscription, Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { SigninModel } from 'src/app/_models/user/signin';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models/user/user';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styles: [`
    :host ::ng-deep .p-password input {
    width: 100%;
    padding:1rem;
    }

    :host ::ng-deep .pi-eye{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--brandColor) !important;
    }

    :host ::ng-deep .pi-eye-slash{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--brandColor) !important;
    }
  `]
})
export class SigninComponent implements OnInit, OnDestroy {

  rememberMe: string[] = ['remember'];
  password: string;
  email: string;
  signinForm: FormGroup
  forgotpasswordForm: FormGroup
  otpForm: FormGroup

  delay = 1 * 60 * 1000;
  timeLeft = 0;
  timeInterval: any;
  timeIntervalString = '1:00 sec';
  showResendCode = false;

  businessList:any[] = []
  currentBusiness:any = null
  isSelectBusiness = false
  mode: string = 'l' // l=login, f=forgot password
  isAdmin:boolean = false;
  config: AppConfig;
  subscription: Subscription;
  _globals = GlobalService

  constructor(
    public _configService: ConfigService,
    private _router: Router,
    private _fb: FormBuilder,
    public _userService: UserService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    if (window.location.host.includes('admin.connect') || window.location.host.includes('admin.') || window.location.host.includes('localhost')) {
      this.isAdmin = true;
    }else{
      this.isAdmin = false
    }

    try {
        document.documentElement.style.setProperty('--brandColor', this._globals.whiteLabel.brandColor);
        document.documentElement.style.setProperty('--bs-primary', this._globals.whiteLabel.brandColor);
        this.config = this._configService.config;
        this.subscription = this._configService.configUpdate$.subscribe(config => {
            this.config = config;
        });
        this.intitializeForm()
        this.initForgotpasswordForm()
        this.initOTPForm()

        let paymentSuccessfully: any = sessionStorage.getItem('payment-successfully');
        if (paymentSuccessfully != undefined) {
            sessionStorage.clear();
        }
    } catch (error) {

    }
  }

  get f() {
    return this.otpForm.controls;
  }
  intitializeForm() {
    this.signinForm = this._fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: ['']
    })
  }

  initForgotpasswordForm() {
    this.forgotpasswordForm = this._fb.group({
      email: ['', [Validators.required]],
    })
  }
  initOTPForm() {
    this.otpForm = this._fb.group({
      otp: ['', [Validators.required]],
    })
    this.otpForm.get('otp').valueChanges.subscribe((val)=>{
      let rawValue = val.replace(/[^0-9]/g, '').slice(0, 6);
      this.otpForm.get('otp').setValue(rawValue)
    })
  }


  isHandheld() {
    if (window.location.href.includes('/handheld'))
      return true
    else
      return false
  }

  emailFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const pvalid = value.match(this._globals.emailValidatorString)
      return pvalid ? null : { emailStrength: true };
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  signin() {
    this._globals.loginErrorMessage = '';
    localStorage.removeItem('user')
    this._abd.showSpinner();
    const x = this.signinForm.value
    const signin: SigninModel = {
      Username: x.email,
      Password: x.password,
      rememberMe: x.rememberMe ? true : false
    }
    this._userService.login(signin)
  }

  setMode(val) {
    this.mode = val
    sessionStorage.removeItem('otpSend');
    if(val === 's') {
    let otpSend: any = sessionStorage.getItem('otpSend');
    if (otpSend == undefined) {
        this.resendCode();
    }
    this.doTimerRoutine();
   }
   this.isSelectBusiness = false
  }

  forgotpasswordSubmit() {
    const email = this.forgotpasswordForm.controls.email.value
    this._abd.showSpinner()
    this._userService.sendForgotpasswordEmail(email).subscribe((res: any) => {
      if (res.success) {
        this._abd.hideSpinner()
        if (res.data) {
          this._connMessageService.showToastMessage('Email has been sent on provided email. Please check your email!', 'success')
          this._router.navigate(['pages/signin'])
        } else {
          this._connMessageService.showToastMessage(res.message, 'error')
          this._router.navigate(['pages/signin'])
        }
      } else {
        this._abd.hideSpinner()
      }
      this._router.navigate(['pages/signin'])
    })
  }

  signupClicked() {
    let url = this._globals.whiteLabel.signupURL
     // this._router.navigate(['setup/subscription-plans'])
  }



  modeChanged() {
    this.mode = this.mode === 'l' ? 'r' : 'l'
  }


  inputStyles:any = {
    color: 'var(--brandColor)',  
    fontSize: 'xx-large',
    width: '240px',
    letterSpacing: '10px',
    background:'#eef0f9',
    border: 'none',
    outline: 'none',
    appearance: 'none',  
    WebkitAppearance: 'none', 
    MozAppearance: 'textfield', 
    borderRadius: '5px',
    fontWeight:'600',
    padding:'10px 30px',

  };

  onFocus() {
    this.inputStyles = {
      ...this.inputStyles,
      background: '#E4E6EF'  
    };
  }

  onBlur() {
    this.inputStyles = {
      ...this.inputStyles, 
       background: '#E4E6EF'
    };
  }
  resendCode() {
   
        this.doTimerRoutine();

        this._userService.getOTP().subscribe((res) => {
            if (res.success) {
                sessionStorage.setItem('otpSend', '1');
                this._connMessageService.showToastMessage(
                    `A new OTP code is sent to your email!`,
                    'success'
                );
            } else {
                this._connMessageService.showToastMessage(
                    `Problem sending OTP!`,
                    'error'
                );
            }
        });
    
}
doTimerRoutine() {

  clearInterval(this.timeInterval);
  this.showResendCode = false;
  this.timeLeft = this.delay - 1000;
  this.timeInterval = setInterval(() => {
      if (this.timeLeft <= 0) {
          clearInterval(this.timeInterval);
          sessionStorage.removeItem('otpSend');
      }
      let d = this.timeLeft / 1000;
      let min = Math.floor(d / 60);
      let sec = d - min * 60;

      this.timeIntervalString =
          min > 0
              ? `${min}:${('00' + sec).slice(-2)} sec`
              : `${('00' + sec).slice(-2)} sec`;
      this.timeLeft -= 1000;
  }, 1000);

  setTimeout(() => {
      this.showResendCode = true;
      clearInterval(this.timeInterval);
  }, this.delay);
}
verifyMyEmail() {
  try {
     
          const obj = {
              Code: this.f.otp.value.replace(/\D/g, ''),
          }
          this._spinner.show();

          this._userService.verifyOTP(obj).subscribe((res) => {
              if (res.success) {
                      if (res.code == 'AS0032906') {
                          res.message =
                              'You have entered invalid otp code';
                     
                      }else if(res.code == 'AS0032903')
                       {
                        
                        if(res.data?.access_token !== undefined && res.data?.refresh_token !== undefined ){
                          const support: User = {
                             userID: '',
                             userName: res.data.Username,
                             displayName: '',
                             token: res.data.access_token,
                             rememberMe: true, 
                             expires_in: res.data.expires_in,
                             refreshToke: res.data.refresh_token,
                             photo: '',
                             type: ''
                           }
                          this._userService.setCurrentUser(support)
                          setTimeout(() => {
                             this.getBusinessList()
                          }, 500);
                          this._connMessageService.showToastMessage(
                           `${res.message}!`,
                           'success'
                       )
                        }else{
                          this._connMessageService.showToastMessage(
                            `${res.message}!`,
                            'error'
                        )
                        }
                        
                        
                      }else {
                        this._connMessageService.showToastMessage(
                          `${res.message}!`,
                          'error'
                      );
                      }

                  
              } else
                  this._connMessageService.showToastMessage(
                      `Unable to verify OTP!`,
                      'error'
                  );
          });
      }catch (error) {}
}
getBusinessList(){
 try {
    this._abd.showSpinner()
 
  this._userService.getAllBusiness().subscribe((res: any) => {
    if (res.success) {
      this.isSelectBusiness = true
      this.businessList = res.data.filter((v)=>v.businessName !== "")
      this._abd.hideSpinner()
    } else {
      this._abd.hideSpinner()
    }})
 } catch (error) {
  this._abd.hideSpinner()
  this._connMessageService.showToastMessage(
    error,
    'error'
);
 }  
}
isBusinessDisabled(business: any): boolean {
  return !business.status;
}
businessChanged(value:any){
  
  // let businessName = 'BOLDVAPESCONNECT360' 
  // const signin: SigninModel = {
  //   Username: businessName,
  //   Password: '12345Aa;',
  //   rememberMe:  false
  // }
  // this._userService.login(signin,true)
}
businessSelected(){

  let businessName = this.currentBusiness?.businessWithDomainName
  // let businessName = 'THRIFTSTOREUK' 
  const signin = {
    CompanyName: businessName,
    Username:'support@saasypos.com',
    rememberMe:false
  }
  this._userService.login(signin,true)
}


}
