
<div class="conn-card">
    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="aduitTrialList"
        responsiveLayout="scroll"
        rowHover="true"
        selectionMode="single"
        dataKey="objectId"
    >
        <ng-template pTemplate="header" let-item>
            <tr>
            
                <th class="text-center">Email</th>
                <th class="text-center">Location</th>
                <th class="text-center">Date</th>
                <th class="text-center">Section</th>
                <th class="text-center">Action</th>
                <th class="text-center">Name</th>
                <th class="text-center" *ngIf="action === 'Add' || action === 'Delete'">Description</th>
                <th class="text-center" *ngIf="action === 'Modify'">Property</th>
                <th class="text-center" *ngIf="action === 'Modify'">Old Value</th>
                <th class="text-center" *ngIf="action === 'Modify'">New Value</th>
                <th class="text-center" *ngIf="aduitTrialList?.length < 2 && isShowhistory(item?.operationType) && action === 'Modify'">History</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td class="text-center px-2">{{ item?.addedBy }}</td>
                <td class="text-center">{{ getMainLocation(item?.location) }}</td>
                <td class="text-center">{{ item?.eventDate | date:'MM/dd/yyyy' }}</td>
                <td class="text-center">{{ item?.operationType }}</td>
                <td class="text-center">{{ item?.eventType }}</td>
                <td class="text-center" >{{ item?.name }}</td>
                <!-- <td class="text-center" >{{ removeDeletedText(item?.name) }}</td> -->
                <td class="text-center" *ngIf="action === 'Add' || action === 'Delete'" >{{ item?.description}}</td>
                <td class="text-center" *ngIf="action === 'Modify'" >
                    <tr class="text-center" *ngFor="let val of item?.changeList">
                        <td class="text-center py-1">{{ val?.prop }}</td>
                    </tr>
                </td>
                <td class="text-center  " *ngIf="action === 'Modify'"> 
                    <tr class="text-center" *ngFor="let val of item?.changeList">
                        <td class="text-center py-1">{{ val?.v1 }}</td>
                    </tr>
                </td>
                <td class="text-center " *ngIf="action === 'Modify'"> 
                    <tr class="text-center" *ngFor="let val of item?.changeList">
                        <td class="text-center py-1">{{ val?.v2 }}</td>
                    </tr>
                </td>
                <td class="text-center" *ngIf="aduitTrialList?.length < 2 && isShowhistory(item?.operationType) && isModefied(item)"> <i class="pi pi-history" (click)="seeHistory(item)"></i> </td>
                
                
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="14" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <br />
    <p-paginator
        #p
        *ngIf="aduitTrialList?.length > 1"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
<ng-container *ngIf="showDetail">
    <p-dialog
        header="Purchase History Detail"
        [(visible)]="showDetail"
        [style]="{ width: '80%' }"
        modal="true"
        [dismissableMask]="true"
        [draggable]="true"
    >
        <!-- <app-customer-purchase-history-detail></app-customer-purchase-history-detail> -->
    </p-dialog>
</ng-container>