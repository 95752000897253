import { PurchaseOrderClosedComponent } from './_components/purchase-orders/purchase-order-closed/purchase-order-closed.component';
import { PoReceiveOpenComponent } from './_components/purchase-orders/po-receive-open/po-receive-open.component';
import { CashReconciliationComponent } from './_components/reports/Financial/cash-reconciliation/cash-reconciliation.component';
import { CashDrawerReportComponent } from './_components/reports/Financial/cash-drawer-report/cash-drawer-report.component';
import { CashCountReportComponent } from './_components/reports/Financial/cash-count-report/cash-count-report.component';
import { DailyCashSummaryBdComponent } from './_components/reports/Financial/daily-cash-summary-bd/daily-cash-summary-bd.component';
import { PurchaseOrderReceiveComponent } from './_components/purchase-orders/purchase-order-receive/purchase-order-receive.component';
import { PurchaseOrderReceiveListComponent } from './_components/purchase-orders/purchase-order-receive-list/purchase-order-receive-list.component';
import { PurchaseOrderCreateEditComponent } from './_components/purchase-orders/purchase-order-create-edit/purchase-order-create-edit.component';
import { PurchaseOrderListComponent } from './_components/purchase-orders/purchase-order-list/purchase-order-list.component';
import { SubscriptionPlansLayoutComponent } from './_components/_signup/subscription-plans/subscription-plans-layout/subscription-plans-layout.component';
import { SignupLayoutComponent } from './_components/_signup/signup-layout/signup-layout.component';
import { TransferReceiveFormComponent } from './_components/transfer-receive/transfer-receive-form/transfer-receive-form.component';
import { TransfersListComponent } from './_components/transfers/transfers-list/transfers-list.component';
import { TransferOutFormComponent } from './_components/transfers/transfer-out-form/transfer-out-form.component';
import { BizzCustomerDetailComponent } from './_business-dashboard/_customers/bizz-customer-detail/bizz-customer-detail.component';
import { BizzCustomersListComponent } from './_business-dashboard/_customers/bizz-customers-list/bizz-customers-list.component';
import { CustomerDetailsComponent } from './_components/customers/customer-details/customer-details.component';
import { CategorySalesReportComponent } from './_components/reports/sales/category-sales-report/category-sales-report.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ChangePasswordComponent } from './_components/signin/change-password/change-password.component';
import { NewEditCustomerComponent } from './_components/customers/new-edit-customer/new-edit-customer.component';
import { UsersListComponent } from './_components/settings/users-list/users-list.component';
import { CreateProductComponent } from './_components/inventory/products/create-product/create-product.component';
import { SalesInvoiceDetailComponent } from './_components/sales-invoices/sales-invoice-detail/sales-invoice-detail.component';
import { SalesInvoicesComponent } from './_components/sales-invoices/sales-invoices/sales-invoices.component';
import { DepartmentSalesReportComponent } from './_components/reports/sales/department-sales-report/department-sales-report.component';
import { ReturnReportComponent } from './_components/reports/sales/return-report/return-report.component';
import { PurchaseReportComponent } from './_components/purchasing/purchase-report/purchase-report.component';
import { InventoryVendorsComponent } from './_components/inventory/inventory-vendors/inventory-vendors.component';
import { InventoryCategoriesComponent } from './_components/inventory/inventory-categories/inventory-categories.component';
import { InventoryDepartmentsComponent } from './_components/inventory/inventory-departments/inventory-departments.component';
import { InventoryReportComponent } from './_components/inventory/inventory-report/inventory-report.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormLayoutComponent } from './components/formlayout/formlayout.component';
import { PanelsComponent } from './components/panels/panels.component';
import { OverlaysComponent } from './components/overlays/overlays.component';
import { MediaComponent } from './components/media/media.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MiscComponent } from './components/misc/misc.component';
import { EmptyComponent } from './components/empty/empty.component';
import { ChartsComponent } from './components/charts/charts.component';
import { FileComponent } from './components/file/file.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { AppMainComponent } from './app.main.component';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { TableComponent } from './components/table/table.component';
import { ListComponent } from './components/list/list.component';
import { TreeComponent } from './components/tree/tree.component';
import { CrudComponent } from './components/crud/crud.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { FloatLabelComponent } from './components/floatlabel/floatlabel.component';
import { InvalidStateComponent } from './components/invalidstate/invalidstate.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { IconsComponent } from './components/icons/icons.component';
import { LandingComponent } from './components/landing/landing.component';
// import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AccessComponent } from './components/access/access.component';

import { SigninComponent } from './_components/signin/signin.component';
import { SubscriptionListComponent } from './_components/subscription-plan/list/list.component';
import { SignupBusinessDetailComponent } from './_components/signup/signup-business-detail/signup-business-detail.component';
import { SignupYoursDetailsComponent } from './_components/signup/signup-yours-details/signup-yours-details.component';
import { HardwarePlanWidgetListComponent } from './_components/shared/hardware-plan-widget-list/hardware-plan-widget-list.component'
import { CheckoutNFinishSignupComponent } from './_components/signup/checkout-n-finish-signup/checkout-n-finish-signup.component'
import { InventoryCountComponent } from './_components/inventory/inventory-count/inventory-count.component';
import { SettingsMainComponent } from './_components/settings/settings-main/settings-main.component';
import { SettingsRightsManagementComponent } from './_components/settings/settings-rights-management/settings-rights-management.component';
import { ProductsComponentList } from './_components/inventory/products/products-list.component';
import { InventoryManufacturerComponent } from './_components/inventory/inventory-manufacturer/inventory-manufacturer.component';
import { HourlySalesReportComponent } from './_components/reports/sales/hourly-sales-report/hourly-sales-report.component';
import { DailySalesReportComponent } from './_components/reports/sales/daily-sales-report/daily-sales-report.component';
import { CreditCardSalesReportComponent } from './_components/reports/sales/credit-card-sales-report/credit-card-sales-report.component';
import { CreditCardSummaryReportComponent } from './_components/reports/sales/credit-card-summary-report/credit-card-summary-report.component';
import { TenderReportComponent } from './_components/reports/sales/tender-report/tender-report.component';
import { SalesTaxReportV2Component } from './_components/reports/sales/sales-tax-report-v2/sales-tax-report-v2.component';
import { LocationsListComponent } from './_components/settings/locations-list/locations-list.component'
import { CustomersComponent } from './_components/customers/customers.component';
import { ImportProductsComponent } from './_components/inventory/import/import.component';
import { CustomerListComponent } from './_components/customers/customer-list/customer-list.component';
import { NewEditUserComponent } from './_components/settings/new-edit-user/new-edit-user.component';
import { BusinessSetupComponent } from './_components/business-setup/business-setup.component';
import { AuthGuard } from './_guards/auth.guard';
import { EmailValidationComponent } from './_components/signup/email-validation/email-validation.component';
import { DailyMoneyReportComponent } from './_components/reports/Financial/daily-money-report/daily-money-report.component';
import { DiscountSalesReportComponent } from './_components/reports/sales/discount-sales-report/discount-sales-report.component';
import { BusinessComponent } from './_components/settings/business/business.component';
import { DiscountsComponent } from './_components/discounts/discounts.component';
import { LoyaltyReportComponent } from './_components/reports/sales/loyalty-report/loyalty-report.component';
import { ProductReportComponent } from './_components/reports/sales/product-report/product-report.component';
import { GrossProfitReportComponent } from './_components/reports/sales/gross-profit-report/gross-profit-report.component';
import { TransferReportComponent } from './_components/reports/sales/transfer-report/transfer-report.component';
import { TransferDetailReportComponent } from './_components/reports/sales/transfer-detail-report/transfer-detail-report.component';
import { TransferReceiveComponent } from './_components/transfer-receive/transfer-receive-list.component';
import { Connect360TermsAndConditionsComponent } from './_components/_static-files/connect360/connect360-terms-and-conditions/connect360-terms-and-conditions.component';
import { CustomerGroupComponent } from './_components/customer-group/customer-group.component';

import { SignupPaymentStep1Component } from './_components/_signup/signup-payment-step1/signup-payment-step1.component';
import { SubscriptionPaymentSuccessfulComponent } from './_components/_signup/subscription-payment-successful/subscription-payment-successful.component';
import { PurchasingReportComponent } from './_components/reports/sales/purchasing-report/purchasing-report.component';
import { InventoryAdjustementSummaryReportComponent } from './_components/reports/sales/inventory-adjustement-summary-report/inventory-adjustement-summary-report.component';
import { InventoryAdjustmentComponent } from './_components/inventory-adjustment/inventory-adjustment.component';
import { InventoryAdjustmentListComponent } from './_components/inventory-adjustment/inventory-adjustment-list/inventory-adjustment-list.component';
import { InventoryAdjustmentDetailComponent } from './_components/reports/sales/inventory-adjustment-detail/inventory-adjustment-detail.component';
import { LoyaltyProgramGeneralComponent } from './_components/loyalty-program/loyalty-program-general/loyalty-program-general.component';
import { RewardConfigurationComponent } from './_components/loyalty-program/reward-configuration/reward-configuration.component';
import { LoyaltyDiscountReportComponent } from './_components/reports/sales/loyalty-discount-report/loyalty-discount-report.component';
import { TieredRewardCustomerReportComponent } from './_components/reports/sales/tiered-reward-customer-report/tiered-reward-customer-report.component';
import { TieredRewardReportComponent } from './_components/reports/sales/tiered-reward-report/tiered-reward-report.component';
import { TieredRewardSummaryReportComponent } from './_components/reports/sales/tiered-reward-summary-report/tiered-reward-summary-report.component';
import { TieredRewardProductReportComponent } from './_components/reports/sales/tiered-reward-product-report/tiered-reward-product-report.component';


import { PoClosedForReceivingComponent } from './_components/purchase-orders/po-closed-for-receiving/po-closed-for-receiving.component';
import { LoyaltyProgramSubscribeComponent } from './_components/loyalty-program/loyalty-program-subscribe/loyalty-program-subscribe.component';
import { PoDirectReceiveComponent } from './_components/purchase-orders/po-direct-receive/po-direct-receive.component';
import { SubscriptionManagementComponent } from './_components/settings/subscription-management/subscription-management/subscription-management.component';
import { TillReportComponent } from './_components/reports/Financial/till-report/till-report.component';
import { NextLevelFormIntegrationComponent } from './_components/next-level-form-integration/next-level-form-integration.component';
import { CashReconciliationNewComponent } from './_components/reports/Financial/cash-reconciliation-new/cash-reconciliation-new.component';
import { ChangeSubscriptionCardComponent } from './_components/settings/subscription-management/change-subscription-card/change-subscription-card.component';
import { PaymentprocessingstatusComponent } from './_components/_signup/paymentprocessingstatus/paymentprocessingstatus.component';
import { SubscriptionPaymentMerchantApplication } from './_components/_signup/subscription-payment-merchant-application/subscription-payment-merchant-application';
import { BizzCouponCodesListComponent } from './_business-dashboard/_coupon-managment/bizz-coupon-codes/bizz-coupon-codes-list/bizz-coupon-codes-list.component';
import { BizzSalesChannelListComponent } from './_business-dashboard/_coupon-managment/bizz-sales-channel/bizz-sales-channel-list/bizz-sales-channel-list.component';
import { BizzCouponSummaryReportListComponent } from './_business-dashboard/_coupon-managment/bizz-reports/cupon-summary/bizz-cupon-summary-report-list.component';
import { BizzSalesChannelReportListComponent } from './_business-dashboard/_coupon-managment/bizz-reports/sales-channel-report/bizz-sales-channel-report-list.component';
import { BarcodeLabePrintingList } from './_components/inventory/barcode-label-printing/barcode-label-printing-list/barcode-label-printing-list.component';
import { TipSummaryReportComponent } from './_components/reports/tip-reports/tip-summary-report/tip-summary-report.component';
import { TipDetailReportComponent } from './_components/reports/tip-reports/tip-detail-report/tip-detail-report.component';
import { TimeTrackingSummaryReportComponent } from './_components/reports/time-tracking-reports/time-tracking-summary-report/time-tracking-summary-report.component';
import { TimeTrackingDetailReportComponent } from './_components/reports/time-tracking-reports/time-tracking-detail-report/time-tracking-detail-report.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { SignupPaymentStep2Component } from './_components/_signup/signup-payment-step2/signup-payment-step2.component';
// import { CashDiscountSalesReportComponent } from './_components/reports/sales/cash-discount-sales-report/cash-discount-sales-report.component';
import { TimeAttendanceUsersListComponent } from './_components/settings/user-time-attendance/time-attendance-users-list/users-list/time-attendance-users-list.component';
// import { CustomerPurchaseHistoryComponent } from './_components/reports/customer-purchase-history/customer-purchase-history.component';
// import { CustomerGroupReportComponent } from './_components/reports/customer-group-reports/customer-group-report.component';
import { AuditTrailComponent } from './_components/reports/audit-trial/audit-trail.component';
// import { CustomerPurchaseHistoryDetailComponent } from './_components/reports/customer-purchase-history/Detail/customer-purchase-history-detail.component';
import { CashDiscountSalesReportComponent } from './_components/reports/sales/cash-discount-sales-report/cash-discount-sales-report.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivate: [AuthGuard],
                children: [

                    //  {path: '', component: ImportProductsComponent},
                    { path: 'uikit/subscription', component: SubscriptionListComponent }, //--
                    { path: 'uikit/signupBusinessDetail', component: SignupBusinessDetailComponent }, //--
                    { path: 'uikit/signupYourDetail', component: SignupYoursDetailsComponent }, //--
                    { path: 'uikit/HardwarePlanWidget', component: HardwarePlanWidgetListComponent }, //--
                    { path: 'uikit/CheckoutNFinishSignupComponent', component: CheckoutNFinishSignupComponent }, //--
                    { path: 'app/inventory-report', component: InventoryReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                       permissions: {
                        only: ['2061']
                      }}
                    }, //--

                    { path: 'handheld/' + 'inventory-report', component: InventoryReportComponent }, //--
                    { path: 'handheld/' + 'products', component: ProductsComponentList }, //--
                    { path: 'handheld/' + 'departments', component: InventoryDepartmentsComponent }, //--
                    { path: 'handheld/' + 'categories', component: InventoryCategoriesComponent }, //--
                    { path: 'handheld/' + 'vendors', component: InventoryVendorsComponent }, //--
                    { path: 'handheld/' + 'manufacturer', component: InventoryManufacturerComponent }, //--
                    { path: 'handheld/' + 'inventory-count', component: InventoryCountComponent }, //--
                    { path: 'handheld/' + 'product-report', component: ProductReportComponent }, //--
                    { path: 'handheld/' + 'department-sales-report', component: DepartmentSalesReportComponent }, //--
                    { path: 'handheld/' + 'transfers-list', component: TransfersListComponent }, //--
                    { path: 'handheld/' + 'transfers-receive-list', component: TransferReceiveComponent }, //--
                    { path: 'handheld/' + 'transfers-receive-form', component: TransferReceiveFormComponent }, //--
                    { path: 'handheld/' + 'transfer-out-form', component: TransferOutFormComponent }, //--

                    { path: 'app/inventory-count', component: InventoryCountComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2061','2032']
                      }}
                    }, //--
                    { path: 'app/products', component: ProductsComponentList,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2031']
                      }}
                    }, //--
                    { path: 'app/label-printing', component: BarcodeLabePrintingList,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2062']
                      }} 
                    }, //--
                    { path: 'app/new-product', component: CreateProductComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2031']
                      }}
                    }, //--
                    { path: 'app/importproducts', component: ImportProductsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2031']
                      }} 
                    }, //--
                    { path: 'app/manufacturer', component: InventoryManufacturerComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2029']
                      }} 
                    }, //--
                    { path: 'app/departments', component: InventoryDepartmentsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2028']
                      }} 
                    }, //--
                    { path: 'app/categories', component: InventoryCategoriesComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2027']
                      }} 
                    }, //--
                    { path: 'app/vendors', component: InventoryVendorsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2030']
                      }} 
                    }, //--
                    { path: 'app/customers', component: CustomersComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2046']
                      }} 
                    }, //--
                    { path: 'app/locations-list', component: LocationsListComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2011','2012','2013','2014','2015']
                      }} 
                    }, //--
                    { path: 'app/business', component: BusinessComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2091','2018','2019','2020','2021','2022','2023','2024','2092','2093','2094']
                      }} 
                    }, //--
                    { path: 'app/discounts', component: DiscountsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2089']
                      }} 
                    }, //--
                    { path: 'app/transfer-out-form', component: TransferOutFormComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/transfers-list', component: TransfersListComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/transfers-receive-list', component: TransferReceiveComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/transfers-receive-form', component: TransferReceiveFormComponent,
                      data:{
                      }
                     }, //--

                    // bizz-dashboard
                    { path: 'bizz/customers-list', component: BizzCustomersListComponent },
                    { path: 'bizz/customers-detail', component: BizzCustomerDetailComponent },
                    { path: 'bizz/sales-channel', component: BizzSalesChannelListComponent },
                    { path: 'bizz/coupon-codes', component: BizzCouponCodesListComponent },
                    { path: 'bizz/sales-channel-report', component: BizzSalesChannelReportListComponent },
                    { path: 'bizz/coupon-summary', component: BizzCouponSummaryReportListComponent },             
                    { path: 'app/purchase-report', component: PurchaseReportComponent }, //--
                    { path: 'app/customer-list', component: CustomerListComponent }, //--
                    { path: 'app/customer-details', component: CustomerDetailsComponent,
       
                     }, //--
                    { path: 'app/customer-group', component: CustomerGroupComponent }, //--
                    // { path: 'app/customer-purchase-summary', component: CustomerPurchaseHistoryComponent }, //--
                    // { path: 'app/customer-purchase-details', component: CustomerPurchaseHistoryDetailComponent }, //--
                    { path: 'app/audit-trail', component: AuditTrailComponent,
             
                     }, //--
                    // { path: 'app/customer-group-report', component: CustomerGroupReportComponent }, //--
                    { path: 'app/users-list', component: UsersListComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2006','2007','2008','2009','2010']
                      }
                    }
                 }, //--
                    { path: 'app/time-attendance', component: TimeAttendanceUsersListComponent,
                    // canActivate: [NgxPermissionsGuard],
                    data: {
                    //   permissions: {
                    //     only: ['2006','2007','2008','2009','2010']
                    //   }
                    }
                 }, //--
                    { path: 'app/users-new-edit', component: NewEditUserComponent,
                      data:{
                    }
                    }, //--
                    { path: 'app/customer-new-edit', component: NewEditCustomerComponent,
                      data:{
                      }
                    }, //--

                    { path: 'app/return-report', component: ReturnReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2050']
                      }} 
                    }, //--
                    { path: 'app/hourly-sales-report', component: HourlySalesReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2049']
                      }} 
                    }, //--
                    { path: 'app/daily-sales-report', component: DailySalesReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2048']
                      }} 
                    }, //--
                    { path: 'app/sales-tax-report-v2', component: SalesTaxReportV2Component,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2054']
                      }} 
                    }, //--
                    { path: 'app/tender-report', component: TenderReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2065']
                      }} 
                    }, //--
                    { path: 'app/credit-card-sales-report', component: CreditCardSalesReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2052']
                      }} 
                    }, //--
                    { path: 'app/credit-card-summary-report', component: CreditCardSummaryReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2053']
                      }} 
                    }, //--
                    { path: 'app/cash-discount-sales-report', component: CashDiscountSalesReportComponent,
                    // canActivate: [NgxPermissionsGuard],
                    data: {
                      // permissions: {
                      //   only: ['2053']
                      // }
                    } 
                    }, //--
                    { path: 'app/department-sales-report', component: DepartmentSalesReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2051']
                      }} 
                    }, //--
                    { path: 'app/category-sales-report', component: CategorySalesReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2063']
                      }} 
                    }, //--
                    { path: 'app/daily-money-report', component: DailyMoneyReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2056']
                      }} 
                    }, //--
                    { path: 'app/discount-sales-report', component: DiscountSalesReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2088']
                      }} 
                    }, //--
                    { path: 'app/loyalty-report', component: LoyaltyReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2076']
                      }} 
                    }, //--
                    { path: 'app/product-report', component: ProductReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2104']
                      }} 
                    }, //--
                    { path: 'app/transfer-report', component: TransferReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2071']
                      }} 
                    }, //--
                    { path: 'app/transfer-detail-report', component: TransferDetailReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2072']
                      }} 
                    }, //--
                    { path: 'app/gross-profit-report', component: GrossProfitReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2087']
                      }} 
                    }, //--
                    { path: 'app/app-purchasing-report', component: PurchasingReportComponent },
                    { path: 'app/app-inventory-adjustement-summary-report', component: InventoryAdjustementSummaryReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2073']
                      }} 
                    },
                    { path: 'app/app-inventory-adjustment', component: InventoryAdjustmentComponent,
                      data:{
                      }
                     },
                    { path: 'app/app-inventory-adjustment-list', component: InventoryAdjustmentListComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2034']
                      }} 
                    },
                    { path: 'app/app-inventory-adjustment-detail', component: InventoryAdjustmentDetailComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2074']
                      }} 
                    },
                    { path: 'app/app-loyalty-discount-report', component: LoyaltyDiscountReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2077']
                      }} 
                    },
                    { path: 'app/app-tiered-reward-customer-report', component: TieredRewardCustomerReportComponent },
                    { path: 'app/app-tiered-reward-report', component: TieredRewardReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2078']
                      }} 
                    },
                    { path: 'app/app-tiered-reward-summary-report', component: TieredRewardSummaryReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2079']
                      }} 
                    },
                    { path: 'app/app-tiered-reward-product-report', component: TieredRewardProductReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2080']
                      }} 
                    },
                    { path: 'app/tip-summary', component: TipSummaryReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2082']
                      }} 
                    },
                    { path: 'app/tip-detail-report', component: TipDetailReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2083']
                      }} 
                    },
                    { path: 'app/time-tracking-summary', component: TimeTrackingSummaryReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2085']
                      }} 
                    },
                    { path: 'app/time-tracking-detail-report', component: TimeTrackingDetailReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2086']
                      }} 
                    },

                    { path: 'app/sales-invoices', component: SalesInvoicesComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2044'] 
                      }
                    }
                }, //--
                    { path: 'app/purchase-orders', component: PurchaseOrderListComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2035']
                      }} 
                    }, //--
                    { path: 'app/purchase-orders-create-edit', component: PurchaseOrderCreateEditComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/purchase-orders-receive-open', component: PoReceiveOpenComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/purchase-orders-receive-closed-for-receiving', component: PoClosedForReceivingComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/purchase-orders-receive-list', component: PurchaseOrderReceiveListComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/purchase-orders-receive', component: PurchaseOrderReceiveComponent,
                      // data:{
                      // }
                     }, //--
                    { path: 'app/purchase-orders-closed', component: PurchaseOrderClosedComponent,
                      // data:{
                      // }
                     }, //--
                    { path: 'app/purchase-orders-receive-direct', component:  PoDirectReceiveComponent,
                      data:{
                      }
                    }, //--

                    { path: 'app/sales-invoice-detail', component: SalesInvoiceDetailComponent,
                      data:{
                      }
                     }, //--
                    { path: 'app/daily-cash-summarybd', component: DailyCashSummaryBdComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2066']
                      }} 
                    }, //--
                    { path: 'app/till-summary-report', component: TillReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2069']
                      }} 
                    }, //--
                    { path: 'app/cash-count-report', component: CashCountReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2067']
                      }} 
                    }, //--
                    { path: 'app/cash-drawer-report', component: CashDrawerReportComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2068']
                      }} 
                    }, //--
                    { path: 'app/cash-reconciliation-report', component: CashReconciliationComponent  }, //--
                    { path: 'app/app-cash-reconciliation-new', component: CashReconciliationNewComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2090']
                      }} 
                    },

                    { path: 'static/connect360-terms-and-conditions', component: Connect360TermsAndConditionsComponent }, //--

                    { path: 'app/settings-main', component: SettingsMainComponent }, //--
                    { path: 'app/settings-rights-management', component: SettingsRightsManagementComponent }, //--
                    { path: 'uikit/formlayout', component: FormLayoutComponent },
                    { path: 'uikit/input', component: InputComponent },
                    { path: 'uikit/floatlabel', component: FloatLabelComponent },
                    { path: 'uikit/invalidstate', component: InvalidStateComponent },
                    { path: 'uikit/button', component: ButtonComponent },
                    { path: 'uikit/table', component: TableComponent },
                    { path: 'uikit/list', component: ListComponent },
                    { path: 'uikit/tree', component: TreeComponent },
                    { path: 'uikit/panel', component: PanelsComponent },
                    { path: 'uikit/overlay', component: OverlaysComponent },
                    { path: 'uikit/media', component: MediaComponent },
                    { path: 'uikit/menu', loadChildren: () => import('./components/menus/menus.module').then(m => m.MenusModule) },
                    { path: 'uikit/message', component: MessagesComponent },
                    { path: 'uikit/misc', component: MiscComponent },
                    { path: 'uikit/charts', component: ChartsComponent },
                    { path: 'uikit/file', component: FileComponent },
                    { path: 'pages/crud', component: CrudComponent },
                    { path: 'pages/timeline', component: TimelineComponent },
                    { path: 'pages/empty', component: EmptyComponent },
                    { path: 'icons', component: IconsComponent },
                    { path: 'blocks', component: BlocksComponent },
                    { path: 'documentation', component: DocumentationComponent },

                    { path: '', component: DashboardComponent, pathMatch: 'full',
                      data:{
                      } },
                    { path: 'app/loyalty-program-general', component: LoyaltyProgramGeneralComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2101']
                      }} 
                    }, //--
                    { path: 'app/reward-configuration', component: RewardConfigurationComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      permissions: {
                        only: ['2102']
                      }} 
                    }, //--
                    { path: 'app/loyalty-program-subscribe', component: LoyaltyProgramSubscribeComponent,
                    // canActivate: [NgxPermissionsGuard],
                    // data: {
                      // titlr:'',
                    //   permissions: {
                        // only: ['2100']
                    //   }
                    // } 
                    }, //--
                    // { path: 'handheld', component: InventoryReportComponent }, //--

                    {
                        path: 'app/subscription-management',
                        component: SubscriptionManagementComponent,
                        canActivate: [NgxPermissionsGuard],
                        data: {
                          permissions: {
                            only: ['2095','2096','2097','2098','2099']
                          }} 
                        
                    },


                ],
            },

            { path: 'setup/signup', component: SignupLayoutComponent, pathMatch: 'full',
              data:{
              }
             }, //--
            { path: 'setup/subscription-plans', component: SubscriptionPlansLayoutComponent,
              data:{
              }
            }, //--
            { path: 'setup/payment_process', component: SignupPaymentStep1Component, pathMatch: 'full' },
            { path: 'setup/spayment_process', component: SignupPaymentStep2Component, pathMatch: 'full' },
            { path: 'setup/payment.html', component: PaymentprocessingstatusComponent, pathMatch: 'full' },
            { path: 'app/payment_process', component: ChangeSubscriptionCardComponent, pathMatch: 'full' },
            { path: 'setup/subscription-payment-success', component: SubscriptionPaymentSuccessfulComponent, pathMatch: 'full' },
            { path: 'setup/subscription-payment-success-merchant', component: SubscriptionPaymentMerchantApplication, pathMatch: 'full' },
     
            { path: 'pages/aboutus', component: AboutUsComponent },
            { path: 'pages/landing', component: LandingComponent },

            { path: 'pages/signin', component: SigninComponent,
              data:{
              }
             }, //--
            { path: 'handheld/signin', component: SigninComponent }, //--

            { path: 'pages/next-level-form', component: NextLevelFormIntegrationComponent, pathMatch: 'full' },

            { path: 'pages/change-password', component: ChangePasswordComponent ,
            // canActivate: [NgxPermissionsGuard],
            // data: {
            //   permissions: {
            //     only: ['2005']
            //   }} 
            }, //--
            { path: 'setup/business-setup', component: BusinessSetupComponent,
              data:{
              }
             }, //--

            { path: 'pages/error', component: ErrorComponent },
            { path: 'pages/emailverified', component: EmailValidationComponent },
            { path: 'pages/notfound', component: NotfoundComponent },
            { path: 'pages/access', component: AccessComponent },
            // { path: '**', redirectTo: 'pages/notfound',  },
            {
                path: '**', component: NotfoundComponent,
            },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
