import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PrintingConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-printing-configuration',
  templateUrl: './printing-configuration.component.html',
  styleUrls: ['./printing-configuration.component.scss']
})
export class PrintingConfigurationComponent implements OnInit {

  printingConfigurationform: FormGroup;
  printSettingData: PrintingConfig;
  noOfCopiesPrint :any[] = [
    {value:0 , title:1},
    {value:1 , title:2},
    {value:2 , title:3},
  ]
  rePrintType:any[]=[
    {value:0 , title:"Both"},
    {value:1 , title:"InvoiceReceipt"},
    {value:2 , title:"CreditDebitReceipt"},
  ]
  backOfficieCopy :any[] = [
    {value:0 , title:"Primary"},
    {value:1 , title:"Secondary"}
  ]

  enableEmailReceipt:boolean = false

  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
               private _confirmationService: ConfirmationService,
              private _messageService: MessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.printSettingData = {
      active: this._abd.systemConfigs.printingConfig.active,
      enableRecieptPrinting: this._abd.systemConfigs.printingConfig.enableRecieptPrinting,
      enableElectricPaymentPrinting: this._abd.systemConfigs.printingConfig.enableElectricPaymentPrinting,
      enableCashDropPrinting: this._abd.systemConfigs.printingConfig.enableCashDropPrinting,
      enablePayInPrinting: this._abd.systemConfigs.printingConfig.enablePayInPrinting,
      enablePayOutPrinting: this._abd.systemConfigs.printingConfig.enablePayOutPrinting,
      enableCashCountPrinting: this._abd.systemConfigs.printingConfig.enableCashCountPrinting,
      printAdditionalReceipt: this._abd.systemConfigs.printingConfig.printAdditionalReceipt,
      enableTillPrinting: this._abd.systemConfigs.printingConfig.enableTillPrinting,
      invoicePrintCopyCount: this._abd.systemConfigs.printingConfig.invoicePrintCopyCount,
      printBackOfficeCopy: this._abd.systemConfigs.printingConfig.printBackOfficeCopy,
      backOfficeCopyPrinter: this._abd.systemConfigs.printingConfig.backOfficeCopyPrinter,
      isEnableReprint: this._abd.systemConfigs.printingConfig.isEnableReprint,
      reprintType: this._abd.systemConfigs.printingConfig.reprintType,
      isShowVoidItemsOnReciept: this._abd.systemConfigs.printingConfig.isShowVoidItemsOnReciept,
      enableReceiptBarCodePrinting: this._abd.systemConfigs.printingConfig.enableReceiptBarCodePrinting,
      footerText: this._abd.systemConfigs.printingConfig.footerText,
      enableEmailReceipt: this._abd.systemConfigs.printingConfig.enableEmailReceipt,
      emailReceiptSubject: this._abd.systemConfigs.printingConfig.emailReceiptSubject || 'Your Invoice from [StoreName]',
      emailReceiptBody: this._abd.systemConfigs.printingConfig.emailReceiptBody,
    }
  }

  defaultTemplate(value){
   let defaultTemplate = `
 <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
    <div style="max-width: 600px; margin: 20px auto; background-color: #ffffff; border: 1px solid #e0e0e0; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); overflow: hidden;">
        <div style="padding: 20px; color: #333; line-height: 1.6;">
            <p>Dear [CustomerName],</p>
            <p>Thank you for shopping with us at <strong>[StoreName]</strong>! You can view and download your invoice for your recent purchase using the link below:</p>
            <p style="text-align: center;">
                <a href="[InvoiceURL]" class="button" style="display: inline-block; background-color: #23DC32; color: white; padding: 10px 20px; border-radius: 4px; text-decoration: none; font-weight: bold; margin: 20px 0;">View Your Invoice</a>
            </p>
            <p style="font-size: 18px;"> <strong>Transaction Summary:</strong></p>
            <ul>
                <li><strong>Date:</strong> [TransactionDate]</li>
                <li><strong>Invoice #:</strong> [InvoiceNumber]</li>
                <li><strong>Payment Method:</strong> [PaymentMethod]</li>
                <li><strong>Total Amount Paid:</strong> $[GrandTotal]</li>
            </ul>
            <p>Thank you for choosing <strong>[StoreName]</strong>. We look forward to serving you again soon!</p>
        </div>
        <div style="background-color: #f9f9f9; text-align: start; padding: 10px 20px; font-size: 12px; color: #777;">
         
            <p><strong>Best regards,</strong></p>
            <p><strong>[BusinessName]</strong></p>
            <p>[LocationPhoneNumber]</p>
            <p>[LocationAddress]</p>
            <p>[BusinessEmail]</p>
            <p>This is an automated email. Please do not reply directly to this message.</p>
        </div>
    </div>
</body>
</html>
`
if(value === false){
  if(this._abd.systemConfigs.printingConfig.emailReceiptBody === undefined){
    this.printingConfigurationform.get('emailReceiptBody').setValue(defaultTemplate)
  }
}else{
  this._confirmationService.confirm({
    message: `Clicking 'Yes' will delete your current content. Are you sure you want to proceed?`,
    header: 'Email Body',
    icon: 'pi pi-info-circle',
    accept: () => {
          this.printingConfigurationform.get('emailReceiptBody').setValue(defaultTemplate)
    },
   
  });
}
    
  }

  ngAfterContentInit() {
    this.initForm()
    const enable=this.printingConfigurationform.get('enableConfigurationForm');
    enable?.valueChanges
      .subscribe(value=>this.disableControls(value))
  }
  get f(){
    return this.printingConfigurationform.controls;
  }

  disableControls(value){
    if(value==false){
      this.printingConfigurationform.get('receiptPrinting')?.disable();
      this.printingConfigurationform.get('paymentPrinting')?.disable();
      this.printingConfigurationform.get('cashdropPrinting')?.disable();
      this.printingConfigurationform.get('payinPrinting')?.disable();
      this.printingConfigurationform.get('payoutPrinting')?.disable();
      this.printingConfigurationform.get('cashCountPrinting')?.disable();
      this.printingConfigurationform.get('printAdditionalReceipt')?.disable();
      this.printingConfigurationform.get('enableTillPrinting')?.disable();
      this.printingConfigurationform.get('invoicePrintCopyCount')?.disable();
      this.printingConfigurationform.get('printBackOfficeCopy')?.disable();
      this.printingConfigurationform.get('backOfficeCopyPrinter')?.disable();
      this.printingConfigurationform.get('isEnableReprint')?.disable();
      this.printingConfigurationform.get('reprintType')?.disable();
      this.printingConfigurationform.get('voidItemPrinting')?.disable();
      this.printingConfigurationform.get('barcodePrinting')?.disable();
      this.printingConfigurationform.get('footerText')?.disable();
      this.printingConfigurationform.get('enableEmailReceipt')?.disable();
      this.printingConfigurationform.get('emailReceiptSubject')?.disable();
      this.printingConfigurationform.get('emailReceiptBody')?.disable();
    }else if (value==true){
      this.printingConfigurationform.get('receiptPrinting')?.enable();
      this.printingConfigurationform.get('paymentPrinting')?.enable();
      this.printingConfigurationform.get('cashdropPrinting')?.enable();
      this.printingConfigurationform.get('payinPrinting')?.enable();
      this.printingConfigurationform.get('payoutPrinting')?.enable();
      this.printingConfigurationform.get('cashCountPrinting')?.enable();
      this.printingConfigurationform.get('printAdditionalReceipt')?.enable();
      this.printingConfigurationform.get('enableTillPrinting')?.enable();
      this.printingConfigurationform.get('invoicePrintCopyCount')?.enable();
      this.printingConfigurationform.get('printBackOfficeCopy')?.enable();
      this.printingConfigurationform.get('backOfficeCopyPrinter')?.enable();
      this.printingConfigurationform.get('isEnableReprint')?.enable();
      this.printingConfigurationform.get('reprintType')?.enable();
      this.printingConfigurationform.get('voidItemPrinting')?.enable();
      this.printingConfigurationform.get('barcodePrinting')?.enable();
      this.printingConfigurationform.get('footerText')?.enable();
      this.printingConfigurationform.get('enableEmailReceipt')?.enable();
      this.printingConfigurationform.get('emailReceiptSubject')?.enable();
      this.printingConfigurationform.get('emailReceiptBody')?.enable();
    }
  }

  initForm() {
    this.printingConfigurationform = this._fb.group({
      enableConfigurationForm: [this.printSettingData.active, Validators.required],
      receiptPrinting : [{value:this.printSettingData.enableRecieptPrinting,disabled:!this.printSettingData.active}, Validators.required],
      paymentPrinting : [{value:this.printSettingData.enableElectricPaymentPrinting,disabled:!this.printSettingData.active}, Validators.required],
      cashdropPrinting : [{value:this.printSettingData.enableCashDropPrinting,disabled:!this.printSettingData.active}, Validators.required],
      payinPrinting : [{value:this.printSettingData.enablePayInPrinting,disabled:!this.printSettingData.active}, Validators.required],
      payoutPrinting : [{value:this.printSettingData.enablePayOutPrinting,disabled:!this.printSettingData.active}, Validators.required],
      cashCountPrinting : [{value:this.printSettingData.enableCashCountPrinting,disabled:!this.printSettingData.active}, Validators.required],
      printAdditionalReceipt : [{value:this.printSettingData.printAdditionalReceipt,disabled:!this.printSettingData.active}, Validators.required],
      enableTillPrinting : [{value:this.printSettingData.enableTillPrinting,disabled:!this.printSettingData.active}, Validators.required],
      invoicePrintCopyCount : [{value:this.printSettingData.invoicePrintCopyCount,disabled:!this.printSettingData.active}, Validators.required],
      printBackOfficeCopy : [{value:this.printSettingData.printBackOfficeCopy,disabled:!this.printSettingData.active}, Validators.required],
      backOfficeCopyPrinter : [{value:this.printSettingData.backOfficeCopyPrinter,disabled:!this.printSettingData.active}, Validators.required],
      isEnableReprint : [{value:this.printSettingData.isEnableReprint,disabled:!this.printSettingData.active}, Validators.required],
      reprintType : [{value:this.printSettingData.reprintType,disabled:!this.printSettingData.active}, Validators.required],
      voidItemPrinting : [{value:this.printSettingData.isShowVoidItemsOnReciept,disabled:!this.printSettingData.active}, Validators.required],
      barcodePrinting : [{value:this.printSettingData.enableReceiptBarCodePrinting,disabled:!this.printSettingData.active}, Validators.required],
      footerText: [{value:this.printSettingData.footerText,disabled:!this.printSettingData.active}],
      enableEmailReceipt: [{value:this.printSettingData.enableEmailReceipt,disabled:!this.printSettingData.active}],
      emailReceiptSubject: [{value:this.printSettingData.emailReceiptSubject,disabled:!this.printSettingData.active}],
      emailReceiptBody: [{value:this.printSettingData.emailReceiptBody,disabled:!this.printSettingData.active}],
    })
    this.defaultTemplate(false)
  }

  saveFn(){
                if(this.f.enableEmailReceipt.value === true){
                if (this.f.emailReceiptSubject.value.trim() == '') {
                    this._messageService.add({ severity: 'error', summary: 'Please add subject' })
                    return false;
                }else  if (this.f.emailReceiptBody.value == '' || this.f.emailReceiptBody.value == null ) {
                  this._messageService.add({ severity: 'error', summary: 'Please add  email body' })
                  return false;
              }
            }
    
    let printingValue: PrintingConfig = {
      active: this.printingConfigurationform.get('enableConfigurationForm').value,
      enableRecieptPrinting: this.printingConfigurationform.get('receiptPrinting').value,
      enableElectricPaymentPrinting: this.printingConfigurationform.get('paymentPrinting').value,
      enableCashDropPrinting: this.printingConfigurationform.get('cashdropPrinting').value,
      enablePayInPrinting: this.printingConfigurationform.get('payinPrinting').value,
      enablePayOutPrinting: this.printingConfigurationform.get('payoutPrinting').value,
      enableCashCountPrinting: this.printingConfigurationform.get('cashCountPrinting').value,
      printAdditionalReceipt: this.printingConfigurationform.get('printAdditionalReceipt').value,
      enableTillPrinting: this.printingConfigurationform.get('enableTillPrinting').value,
      invoicePrintCopyCount: this.printingConfigurationform.get('invoicePrintCopyCount').value,
      printBackOfficeCopy: this.printingConfigurationform.get('printBackOfficeCopy').value,
      backOfficeCopyPrinter: this.printingConfigurationform.get('backOfficeCopyPrinter').value,
      isEnableReprint: this.printingConfigurationform.get('isEnableReprint').value,
      reprintType: this.printingConfigurationform.get('reprintType').value,
      isShowVoidItemsOnReciept: this.printingConfigurationform.get('voidItemPrinting').value,
      enableReceiptBarCodePrinting: this.printingConfigurationform.get('barcodePrinting').value,
      footerText: this.printingConfigurationform.get('footerText').value,
      enableEmailReceipt: this.printingConfigurationform.get('enableEmailReceipt').value,
      emailReceiptSubject: this.printingConfigurationform.get('emailReceiptSubject').value,
      emailReceiptBody: this.printingConfigurationform.get('emailReceiptBody').value,
      isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.savePrintingConfiguration(printingValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.printingConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Print settings saved successfully!`, 'success')
          this._businessSetupService.showPrintingConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("printing");
              this._abd.systemConfigs.printingConfig = res.data.printingConfig;
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving print settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showPrintingConfiguration = false
  }

}
