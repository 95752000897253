
<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'audit-trial'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <br />
    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="aduitTrialList"
        responsiveLayout="scroll"
        rowHover="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        (onRowSelect)="onRowSelect($event)"
        dataKey="objectId"
    >
        <ng-template pTemplate="header">
            <tr>
            
                <th class="text-center">Date</th>
                <th class="text-center">User Name</th>
                <th class="text-center">Section</th>
                <th class="text-center">Action</th>
                <th class="text-center">Description</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td class="text-center">{{ item?.eventDate | date:'MM/dd/yyyy' }}</td>
                <td class="text-center">{{ getUserWithId(item?.addedBy) }}</td>
                <td class="text-center">
                    {{ item?.operationType }}
                </td>
                <td class="text-center">{{ item?.eventType }}</td>
                <td class="text-center" style="width: 500px;">{{ item?.briefDescription }}</td>
                
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="14" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <br />
    <p-paginator
        #p
        *ngIf="aduitTrialList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
<ng-container *ngIf="showDetail">
    <p-dialog
        header="Audit Trial Details"
        [(visible)]="showDetail"
        [style]="{ width: '90%' }"
        modal="true"
        [dismissableMask]="true"
        [draggable]="true"
    >
    <app-audit-trial-details [objectId]="this._auditTrailServices?.selectedAudit" [action]="actionType"></app-audit-trial-details>
    </p-dialog>
</ng-container>