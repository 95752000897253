<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <!-- <label class="control-label"
                    ><strong
                        >Invoice Configuration
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                > -->
                <br />
                <form
                    [formGroup]="cashdropform"
                    (ngSubmit)="cashdropform.valid && saveFn()"
                    autocomplete="off"
                >
                    <div class="mb-4">
                        <p-inputSwitch [formControl]="$any(cashdropform.controls)['enableCashdropForm']" inputId="rememberme2"></p-inputSwitch> 
                        <label class="control-label" for="rememberme2"><strong> Enable</strong></label>
                    </div>
                    <br/>
                    <label class="control-label"
                        ><strong>Cash Drop Threshold ($)</strong></label>
                    <app-text-input
                        [formControl]="$any(cashdropform.controls)['cashdropThreshold']"
                        [label]=""
                        [name]="'Cash Drop Threshold ($) Value'"
                        (input)="10"
                        [description]="'System will lock invoice creation when threshold reaches'"
                    ></app-text-input>
                    <div
                        *ngIf="f.cashdropThreshold.touched && f.cashdropThreshold.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.cashdropThreshold.errors.pattern">Value should be number</div>
                    </div>
                    <br/>
                    
                    <label class="control-label"
                        ><strong>Cash Drop Warning ($)</strong></label>
                    <app-text-input
                        [formControl]="$any(cashdropform.controls)['cashdropWarning']"
                        [label]=""
                        [name]="'Cash Drop Warning ($) Value'"
                        (input)="10"
                        [description]="'System will prompt user to perform cash drop'"
                    ></app-text-input>
                    <div
                        *ngIf="f.cashdropWarning.touched && f.cashdropWarning.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.cashdropWarning.errors.pattern">Value should be number</div>
                    </div>
                    <br/>

                    <!-- <label class="control-label"
                        ><strong>Lock Invoice at ($)</strong></label>
                    <app-text-input
                        [formControl]="$any(cashdropform.controls)['lockInvoice']"
                        [label]=""
                        [name]="'Lock Invoice at ($) Value'"
                        (input)="10"
                        [description]=""
                    ></app-text-input>
                    <div
                        *ngIf="f.lockInvoice.touched && f.lockInvoice.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.lockInvoice.errors.pattern">Value should be number</div>
                    </div> -->
                    <br/>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!cashdropform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
    </div>
    