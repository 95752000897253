import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {
  filter: any = null

  activityType:any[] =[
    {value:'All'},
    {value:'Add'},
    {value:'Modify'},
    {value:'Delete'},
  ]
  operationType:any[] =[
    {value:'All',name:'All'},
    {value:'Product',name:'Product'},
    {value:'ShippingDimensions',name:'Shipping Dimensions'},
    {value:'StockTransfer',name:'Stock Transfer'},
    {value:'StockAdjustment',name:'Stock Adjustment'}, 
    {value:'Discount',name:'Discount'},
    {value:'Customer',name:'Customer'},
    {value:'Categories',name:'Categories'},
    {value:'Department',name:'Department'},
    {value:'Vendor',name:'Vendor'},
    {value:'Manufacturer',name:'Manufacturer'},
    {value:'BarCodeQue',name:'Bar Codes'},
    {value:'InventoryCount',name:'Inventory Count'},
    // {value:'Inventory',name:'Inventory'},
    {value:'PurchaseOrder',name:'Purchase Order'},
    {value:'PurchaseOrderSettings',name:'Purchase Order Settings'},
    {value:'LabelPrinting',name:'Label Printing'},
    {value:'Reconcilation',name:'Reconcilation'},
    {value:'TaxRate',name:'Tax Rate'},
    {value:'InvoiceConfiguration',name:'Invoice Configuration'},
    {value:'PrinterSettings',name:'Printer Settings'},
    {value:'CashDropSettings',name:'Cash Drop Settings'},
    {value:'CashDrawerSettings',name:'Cash Drawer Settings'},
    {value:'SecuritySettings',name:'Security Settings'},
    {value:'BusinessSettings',name:'Business Settings'},
    {value:'TimeAttendance',name:'Time Attendance'},
    {value:'TimeTrackingSettings',name:'Time Tracking Settings'},
    {value:'Users',name:'Users'},
    {value:'Locations',name:'Locations'},
    {value:'CashDrawerCount',name:'Cash Drawer Count'},
    {value:'TenderConfiguration',name:'Tender Configuration'},
    {value:'PerksReward',name:'Perks Reward'},
    {value:'LoyaltyConfigs',name:'Loyalty Configuration'},
    {value:'TenderTypes',name:'Tender Types'},
    // {value:'ContentData',name:'Content Data'},
  ]

  selectedAudit:any = null;

  constructor(
    private _bs: BaseService,
  ) {
    this.filter = {
      searchText: '',
      selectedLocation: '',
    }
  }


  getAuditTrailSummaryList(obj?:any) {
    let url = environment.BusinessBaseURL + 'audit/logs?Type=summary';

    return this._bs.httpPost(url, obj).pipe(map(res => {
      return res
    }))
  }
  getAuditTrailHistoryList(obj?: any) {
    let url = environment.BusinessBaseURL + 'audit/logs?Type=history';

    return this._bs.httpPost(url,obj).pipe(map(res => {
      return res
    }))
  }


}
