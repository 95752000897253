<!-- background-image: url(https://preview.keenthemes.com/metronic8/demo2/assets/media/auth/bg4.jpg); -->
<!-- background-image: url(https://cloudstoragesvc.blob.core.windows.net/commonfiles/DefaultBgImage.png); -->
<div *ngIf="!this.isSelectBusiness"
    class="grid"
    style="
        width: 100%;
        backgrpound-color:white;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        margin: 0;
    "
>
    <div *ngIf="!isAdmin" class="xl:col-6 lg:col-6 col-12" style="
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url('{{_globals.whiteLabel.backgroundImage}}');"
    >
    </div>
   
   <div *ngIf="isAdmin" class="xl:col-6 lg:col-6 col-12" style="
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url('{{_globals.whiteLabel.adminBackgroundImage}}');"
    >
    </div>

    <div
        class="xl:col-6 lg:col-6 col-12"
        style="align-items: center; align-content: center;"
    >
        <div class="conn-card-custom" style="margin: auto auto; max-width: 500px;margin-top:8%">
            <div class="conn-grid">
                <div
                    class="flex align-items-center justify-content-center"
                    style="margin-top: 1rem; margin-bottom: 1rem"
                >
                    <img
                        [src]="_globals.whiteLabel.logo"
                        alt="logo"
                        width="180px"
                    />
                </div>
            </div>

            <div *ngIf="mode == 'l'">
                <form
                    [formGroup]="signinForm"
                    (ngSubmit)="signinForm.valid && signin()"
                    autocomplete="off"
                >
                    <div class="flex align-items-center justify-content-center">
                        <div class="grid w-full">
                            <div class="col">
                                <div class="text-center mb-5">
                                    <div
                                        class="text-900 text-3xl font-medium mb-3 mt-6"
                                        style="display: none"
                                    >
                                        {{
                                            mode === "l"
                                                ? _globals.whiteLabel.slogan
                                                : "Registration"
                                        }}
                                    </div>
                                    <div *ngIf="mode === 'l'">
                                        <span *ngIf="!isAdmin" style="display: block;
                                        color: var(--brandColor);
                                        font-size: 1.5rem;
                                        margin-top: 12px;
                                        margin-bottom: 12px;
                                        font-weight: bolder;">
                                        Welcome to {{_globals.whiteLabel.wlName}}</span>
                                        <span *ngIf="isAdmin" style="display: block;
                                        color: var(--brandColor);
                                        font-size: 1.5rem;
                                        margin-top: 12px;
                                        margin-bottom: 12px;
                                        font-weight: bolder;">
                                        Internal Dashboard</span>

                                        <span
                                            class="text-600 font-medium line-height-3"
                                            >Please enter your credentials to
                                            access your account</span
                                        >
                                    </div>

                                    <div *ngIf="mode === 'r'">
                                        <span
                                            class="text-600 font-medium line-height-3"
                                            >Already have an account?</span
                                        >
                                        <a
                                            class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                                            (click)="modeChanged()"
                                            >Sign In!</a
                                        >
                                    </div>
                                </div>

                                <div class="w-full mx-auto">
                                    <label
                                        for="email"
                                        class="block text-900 text-xl font-medium mb-2"
                                        >Username/Email</label
                                    >
                                    <input
                                        [formControl]="
                                            signinForm.controls['email']
                                        "
                                        label="Email"
                                        placeholder="Username"
                                        pInputText
                                        class="w-full mb-1"
                                        style="padding: 1rem"
                                    />
                                    <label
                                        for="password"
                                        class="block text-900 font-medium text-xl mb-2"
                                        >Password</label
                                    >
                                    <p-password
                                        id="password"
                                        [formControl]="
                                            signinForm.controls['password']
                                        "
                                        placeholder="Password"
                                        [toggleMask]="true"
                                        [feedback]="false"
                                        styleClass="w-full mb-3 p-password p-component p-inputwrapper p-input-icon-right"
                                    >
                                    </p-password>
                                    <a
                                    style="color: var(--brandColor)"
                                    class="flex justify-content-end text-sm"
                                >
                                    <span class="font-medium cursor-pointer" (click)="setMode('f')" > Forgot Password</span>
                                </a>
                                    <div
                                        class="flex align-items-center justify-content-between mb-5"
                                    >
                                        <div class="flex align-items-center">
                                            <!-- <p-checkbox
                                            [formControl]="
                                                signinForm.controls['rememberMe']
                                            "
                                            id="rememberme1"
                                            [binary]="true"
                                            styleClass="mr-2"
                                        ></p-checkbox>
                                        <label for="rememberme1">Remember me</label> -->
                                        </div>
                                        <!-- <a class="font-medium no-underline ml-2 text-right cursor-pointer brand-color">Forgot
                                        password?</a> -->
                                        <div
                                            class="login-message"
                                            *ngIf="
                                                _globals.loginErrorMessage
                                                    .length > 0
                                            "
                                        >
                                            <span
                                                class="login-message-text text-align-center danger"
                                                >{{
                                                    _globals.loginErrorMessage
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                    <button
                                        [disabled]="!signinForm.valid"
                                        pButton
                                        pRipple
                                        label="Sign In"
                                        class="w-full p-3 text-xl mb-3"
                                        style="
                                            background-color: var(--brandColor);
                                        "
                                        isDefault
                                    ></button>
                                    <div class="flex justify-content-center" *ngIf=" !isHandheld() " >
                                    <span *ngIf="!isAdmin">
                                          <span>Not a Member yet? &nbsp; </span>

                                         <!-- <a
                                        style="color: var(--brandColor)"
                                        href="https://su.SaasyPos.com/signup">
                                    
                                        <span> <b>Sign up </b></span>
                                    </a>  -->
                                         <a
                                            style="color: var(--brandColor);cursor:pointer"
                                           
                                            [routerLink]="['/setup/subscription-plans']">
                                            <span> <b>Sign up </b></span>
                                        </a>
                                    </span>
                                      
                                        <!-- (click)="signupClicked()" -->
                                    </div>
                                    <br />
                                    <div class="flex justify-content-center" *ngIf="isAdmin">
                                        <span></span>
                                        <!-- <a
                                            style="color: var(--brandColor)"
                                            href="javascript:void(0)"
                                            (click)="setMode('f')"
                                        >
                                            <span> <b>Forgot Password </b></span>
                                        </a> -->
                                        <a
                                            class="cursor-pointer"
                                            style="color: var(--brandColor)"
                                            (click)="setMode('s')"
                                        >
                                            <span> <b>Login Support Account</b></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div *ngIf="mode == 'f'">
                <p class="text-600 font-medium line-height-3 text-align-center">
                    Please Enter Username to reset Password. Password will be sent to your associated email address.
                </p>
                <br />
                <br />
                <form
                    [formGroup]="forgotpasswordForm"
                    (ngSubmit)="forgotpasswordSubmit()"
                    autocomplete="off"
                >
                    <input
                        [formControl]="forgotpasswordForm.controls['email']"
                        label="Email"
                        placeholder="Username"
                        pInputText
                        class="w-full mb-3"
                        style="padding: 1rem"
                    />
                    <button
                        [disabled]="!forgotpasswordForm.valid"
                        pButton
                        pRipple
                        label="Send Reset Link"
                        class="w-full p-3 text-xl mb-3"
                        style="background-color: var(--brandColor)"
                        isDefault
                    ></button>
                </form>
                <div class="flex justify-content-center">
                    <a
                        style="color: var(--brandColor);cursor:pointer"
                        href="javascript:void(0)"
                        (click)="setMode('l')"
                        >Back to Login
                    </a>
                </div>
            </div>
            <div *ngIf="mode == 's'">
                <form
                [formGroup]="forgotpasswordForm"
                (ngSubmit)="forgotpasswordSubmit()"
                autocomplete="off"
            >
              <span style="display: flex;
              justify-content: center;
              color: var(--brandColor);
              font-size: 1.5rem;
              margin-top: 12px;
              margin-bottom: 12px;
              font-weight: bolder;">
              Support User</span>
              <span style="display: flex;
              justify-content: center;
              font-size: 1.5rem;
              margin-top: 12px;
              margin-bottom: 12px;
              font-weight: bolder;">
                    Verify Your Email Address
              </span> 
                <div class="flex align-items-center justify-content-center" >
                    <p class="text-centertext-gray-600" *ngIf="!showResendCode">Enter Verification Code Here in   <span class=" text-gray-600 font-bold" *ngIf="!showResendCode">{{timeIntervalString}}</span></p>
                    <p class="text-center text-gray-600  " *ngIf="showResendCode">Didn't receive the verification code? <span (click)="resendCode()" class="font-bold cursor-pointer" style="color: var(--brandColor);">Resend Code </span> </p> 
                </div>
                <div class="flex align-items-center flex-column justify-content-center" >
                    
                    <input class="my-4"
                    [ngStyle]="inputStyles"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                     maxlength="6"
                     type="text"
                     [formControl]="otpForm.controls['otp']"
                     inputmode="numeric"
                    />
   
                <button
                [disabled]="!otpForm.valid"
                pButton
                type="button"
                label="Verify My Email"
                (click)="verifyMyEmail()"
            ></button>
        </div>
                <div class="flex justify-content-center my-4">
                    <a
                        style="color: var(--brandColor);cursor:pointer"
                        href="javascript:void(0)"
                        (click)="setMode('l')"
                        >Back to Admin Login
                    </a>
                </div>
                </form>
            </div>

            <div *ngIf="mode == 'r'">
                <h4 style="text-align: center; font-weight: 700">
                    Reset Your Password
                </h4>
                <br />
                <form
                    [formGroup]="resetpasswordForm"
                    (ngSubmit)="
                        resetpasswordForm.valid && resetpassworSubmit()
                    "
                    autocomplete="off"
                >
                    <div>
                        <label
                            for="password"
                            class="block text-900 font-medium text-xl mb-2"
                            >Password</label
                        >
                        <p-password
                            id="password"
                            [formControl]="
                                resetpasswordForm.controls['password']
                            "
                            placeholder="Password"
                            [toggleMask]="true"
                            [feedback]="true"
                            [style]="{ width: '100%', 'margin-top': '8px' }"
                            [inputStyle]="{ width: '100%' }"
                            [strongRegex]="passwordValidatorString"
                            (input)="resetFormOk()"
                            styleClass="w-full mb-3 p-password p-component p-inputwrapper p-input-icon-right"
                        >
                            <ng-template pTemplate="footer">
                                <p-divider></p-divider>
                                <p class="mt-2">Suggestions</p>
                                <ul
                                    class="pl-2 ml-2 mt-0"
                                    style="line-height: 1.5"
                                >
                                    <li>At least one lowercase</li>
                                    <li>At least one uppercase</li>
                                    <li>At least one numeric</li>
                                    <li>At least one special character</li>
                                    <li>Between 8 and 16 characters</li>
                                </ul>
                            </ng-template>
                        </p-password>
                        <div
                            *ngIf="
                                resetpasswordForm.controls.password.touched &&
                                resetpasswordForm.controls.password.invalid
                            "
                            class="invalid-feedback"
                        >
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    resetpasswordForm.controls.password.errors
                                        ?.required ||
                                    resetpasswordForm.controls.password.errors
                                        ?.passwordStrength
                                "
                                style="margin-top: -10px"
                            >
                                Your password must have lower case, upper case,
                                special character, numeric characters and length
                                between 8-16 characters.
                            </div>
                        </div>
                    </div>

                    <br />
                    <div>
                        <label
                            for="password"
                            class="block text-900 font-medium text-xl mb-2"
                            >Confirm Password</label
                        >
                        <p-password
                            id="password"
                            [formControl]="
                                resetpasswordForm.controls['confirmPassword']
                            "
                            placeholder="Confirm Password"
                            [toggleMask]="true"
                            [feedback]="true"
                            [style]="{ width: '100%', 'margin-top': '8px' }"
                            [inputStyle]="{ width: '100%' }"
                            [strongRegex]="passwordValidatorString"
                            (input)="resetFormOk()"
                            styleClass="w-full mb-3 p-password p-component p-inputwrapper p-input-icon-right"
                        >
                            <ng-template pTemplate="footer">
                                <p-divider></p-divider>
                                <p class="mt-2">Suggestions</p>
                                <ul
                                    class="pl-2 ml-2 mt-0"
                                    style="line-height: 1.5"
                                >
                                    <li>At least one lowercase</li>
                                    <li>At least one uppercase</li>
                                    <li>At least one numeric</li>
                                    <li>At least one special character</li>
                                    <li>Between 8 and 16 characters</li>
                                </ul>
                            </ng-template>
                        </p-password>
                        <div
                            *ngIf="
                                resetpasswordForm.controls.confirmPassword
                                    .touched &&
                                resetpasswordForm.controls.confirmPassword
                                    .invalid
                            "
                            class="invalid-feedback"
                        >
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    resetpasswordForm.controls.confirmPassword
                                        .errors?.required ||
                                    resetpasswordForm.controls.confirmPassword
                                        .errors?.conpasswordStrength
                                "
                                style="margin-top: -10px; margin-bottom: 20px ;"
                            >
                                Mismatch passwords.
                            </div>
                        </div>
                    </div>
                    <p
                        class="text-600 font-medium line-height-3 text-align-center"
                    >
                        Please enter password between 8-16 characters long with
                        at least one uppercase character, one numeric and one
                        special character
                    </p>
                    <br />

                    <button
                        [disabled]="!resetpasswordForm.valid || !resetFormOk()"
                        pButton
                        pRipple
                        label="Save"
                        class="w-full p-3 text-xl mb-3"
                        style="background-color: var(--brandColor)"
                        isDefault
                    ></button>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="this.isSelectBusiness"
class="grid"
style="
    width: 100%;
    backgrpound-color:white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    margin: 0;
">
            <div class="conn-grid w-full ">
                <div                    class="flex flex-column align-items-center justify-content-center h-screen">
                    <img
                        [src]="_globals.whiteLabel.logo"
                        alt="logo"
                        width="250px"
                    />
                    <span
                    class="py-4"
                    style="display: flex;
                    justify-content: center;
                    color: var(--brandColor);
                    font-size: 2rem;
                    font-weight: bold;">
                    Select a Business to Manage</span>
                    <p-dropdown
                    [style]="{width:'100%',minWidth:'250px',margin:'5px 0px'}"
                    [(ngModel)]="currentBusiness"
                    (onChange)="businessChanged($event)"
                    class="p-dropdown-1"
                    [options]="businessList"
                    optionLabel="businessName"
                    placeholder="Please select a business"
                    appendTo="body"
                    [filter]="true"
                    [optionDisabled]="isBusinessDisabled"
                    [showClear]="true"
                >
                <ng-template let-business pTemplate="item">
                    <div [style.pointer-events]="business.status ? 'auto' : 'none'"  [ngStyle]="{'color': business.status ? 'black' : 'gray'}" class="flex justify-content-between">
                      <span>{{ business.businessName }}</span> <i class="pi pi-info-circle cursor-pointer" [style.pointer-events]="'auto'" pTooltip="Access denied. Please ask the business owner for permission." tooltipEvent="hover" appendTo="body" *ngIf="!business.status"></i>
                    </div>
                  </ng-template>
                </p-dropdown>
                    <p-button (click)="businessSelected()" class="py-4">Manage Business</p-button>
                
                <div class="flex justify-content-center my-2">
                    <a
                        style="color: var(--brandColor);cursor:pointer"
                        href="javascript:void(0)"
                        (click)="setMode('l')"
                        >Back to Admin Login
                    </a>
                </div>
            </div>
            </div>
</div>
<style>
    h2.brand-slogon {
        color: #414042;
        margin-top: 10px;
        padding: 10px;
    }
</style>
